<template>
    <v-list-item>
        <v-list-item-icon v-if="icon">
            <w-icon
                :value="icon"
                :color="color"
            />
        </v-list-item-icon>
        <v-list-item-content class="py-0">
            <v-list-item-title>{{ $trans(label) }}</v-list-item-title>
            <v-list-item-subtitle v-if="hint">{{ $trans(hint) }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="my-0">
            <slot></slot>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
export default {
    name: 'SettingsFormItem',
    props: {
        label: {
            type: String,
            required: true
        },
        hint: {
            type: String,
            default: undefined
        },
        icon: {
            type: String,
            default: undefined
        },
        color: {
            type: String,
            default: 'primary'
        }
    }
}
</script>

<style lang=scss>

</style>
