<template>
    <content-wrapper
        :title="$trans('Media')"
        :comback="{ name: 'settings' }"
    >
        <template #actions>
            <v-btn
                color="success"
                outlined
                @click="onSaveSettings"
            >
                {{ $trans('Save') }}
            </v-btn>
        </template>

        <settings-form
            :item="itemOfEnabledFileTypes"
            :fields="fieldsOfEnabledFileTypes"
            class="mb-4"
            @input="onInput"
        />
    </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper'
import SettingsForm from '@apps/settings/components/SettingsForm'
import { setMediaSettings } from '@apps/media/api'

export default {
    name: 'MediaManage',
    components: { SettingsForm, ContentWrapper },
    computed: {
        itemOfEnabledFileTypes() {
            return {
                enabledFileTypes: this.$store.getters['media/enabledFileTypes']
            }
        },
        fieldsOfEnabledFileTypes() {
            const availableFileTypes = this.$store.getters['media/availableFileTypes']
            return [
                {
                    label: 'File types',
                    hint: 'hint.media.enabledFileTypes',
                    type: 'v-select',
                    name: 'enabledFileTypes',
                    props: {
                        outlined: true,
                        hideDetails: true,
                        dense: true,
                        multiple: true,
                        items: availableFileTypes
                    }
                }
            ]
        }
    },
    data() {
        return {
            settings: {}
        }
    },
    methods: {
        onInput(field, value) {
            this.settings[field] = value
        },
        async onSaveSettings() {
            this.$lprogress.begin()

            try {
                await setMediaSettings(this.settings)

                await this.$store.dispatch('media/fetchSettings')
            } catch (error) {} finally {
                this.$lprogress.end()
            }
        }
    }
}
</script>

<style lang=scss>

</style>
