<template>
    <div class="settings-form">
        <div v-if="isHeader" class="mb-1 px-4">
            <div class="text-truncate text-uppercase subtitle-2 font-weight-bold">{{ $trans(title) }}</div>
            <div v-if="subtitle" class="body-2">{{ $trans(subtitle) }}</div>
        </div>
        <v-card style="overflow: hidden">
            <v-form
                ref="wSimpleForm"
                v-model="isValid"
                lazy-validation
            >
                <v-list>
                    <template
                        v-for="(field, index) in fields"
                    >
                        <v-divider
                            :key="`v-divider-${index}`"
                            v-if="index > 0"
                        />
                        <settings-form-item
                            :key="`settings-form-item-${index}`"
                            :label="field.label"
                            :hint="field.hint"
                            :icon="field.icon"
                            :color="field.color"
                        >
                            <component
                                :is="field.type"
                                :key="field.name"
                                v-model="formData[field.name]"
                                :name="field.name"
                                :error-messages="getErrors(field.name)"
                                v-bind="field.props"
                                v-on="field.on"
                                @focus="resetErrorMessages"
                                @input="onInput(field.name, formData[field.name])"
                                @change="onChange(field.name, formData[field.name])"
                            />
                        </settings-form-item>
                    </template>
                </v-list>
            </v-form>
        </v-card>
        <div v-if="hint" class="body-2 px-4 mt-1">{{ $trans(hint) }}</div>
    </div>
</template>

<script>
import hasWSimpleFormHandlers from '@/widgets/components/WSimpleForm/mixins/hasWSimpleFormHandlers'
import SettingsFormItem from '@apps/settings/components/SettingsForm/SettingsFormItem'

export default {
    name: 'SettingsForm',
    mixins: [ hasWSimpleFormHandlers ],
    props: {
        title: {
            type: String,
            default: undefined
        },
        subtitle: {
            type: String,
            default: undefined
        },
        hint: {
            type: String,
            default: undefined
        }
    },
    components: { SettingsFormItem },
    computed: {
        isHeader() {
            return this.title || this.subtitle
        },
        settingsListItemsClasses() {
            const classes = []

            if (this.isHeader) {
                classes.push('pt-0')
            }

            if (this.fields.length === 1) {
                classes.push('py-0')
            }

            return classes
        }
    }
}
</script>

<style lang=scss>
    .settings-form {
        .v-list-item__action {
            max-width: 45%;
        }
    }
</style>
